<!-- Homepage.vue -->
<template>
  <div>
    <SiteHeader />

    <div class="page-banner-area bg-color-f8f8f8">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <div class="page-banner-heading">
              <h1>Contact Us</h1>
              <ul>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-area ptb-100">
      <div class="container">
        <div class="section-title">
          <span class="subtitle">Connect with Us</span>
          <h2>We’re Happy To Assist You</h2>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="pel-address">
              <ul>
                <li>
                  <div class="icon">
                    <i class="fa-sharp fa-solid fa-phone"></i>
                  </div>
                  <p>Phone:</p>
                  <a href="tel:+91 7420061266">+91 742 006 1266</a>
                  <a href="tel:+91 7420061259">+91 742 006 1259</a>
                </li>
              </ul>
            </div>

            <div class="pel-address">
              <ul>
                <li>
                  <div class="icon">
                    <i class="fa-solid fa-envelope"></i>
                  </div>
                  <p>Email:</p>
                  <a href="mailto:admin@softwarehub.co.in">admin@softwarehub.co.in</a>
                </li>
              </ul>
            </div>

            <div class="pel-address">
              <ul>
                <li>
                  <div class="icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <p>Location:</p>
                  <span>A-18, Vishwashanti Appartment , Station pada, Kulgaon
                    Badlapur (E) Pin-421503</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-8">
            <div id="contactForm" class="contact-form"  novalidate>
              <div class="row">
                <div class="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                  <div class="form-group">
                    <input v-model="name" type="text" class="form-control" required placeholder="Name" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                  <div class="form-group">
                    <input v-model="email" type="email" class="form-control" required placeholder="Email" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                  <div class="form-group">
                    <input v-model="phone" type="tel" class="form-control" required placeholder="Phone" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                  <div class="form-group">
                    <input v-model="subject" type="text" class="form-control" required placeholder="Subject" />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea v-model="message" class="form-control textarea" cols="30" rows="8" required
                      placeholder="Message"></textarea>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <button class="book-btn default-btn" @click="sendEmail">Send Message</button>
                  <div id="msgSubmit" class="h3 text-center hidden"></div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from "./SiteHeader.vue";
import SiteFooter from "./SiteFooter.vue";

export default {
  name: "ContactUs",
  components: {
    SiteHeader,
    SiteFooter,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };
  },
  methods: {
    sendEmail() {
      // Check if required fields are filled
      if (!this.name || !this.email || !this.phone || !this.subject || !this.message) {
        alert("Please fill in all fields.");
        return;
      }

      // Construct the request body
      const requestBody = new FormData();
      requestBody.append("name", this.name);
      requestBody.append("email", this.email);
      requestBody.append("contact_number", this.phone);
      requestBody.append("subject", this.subject);
      requestBody.append("message", this.message);

      // Make the POST request to your PHP API endpoint
      fetch("https://softwarehub.co.in/softhub/mail.php", {
        method: "POST",
        body: requestBody
      })
        .then(response => {
          if (response.ok) {
            alert("Email sent successfully");
          } else {
            throw new Error("Failed to send email");
          }
        })
        .catch(error => {
          console.error("Error:", error);
          alert("Failed to send email. Please try again later.");
        });
    },
  },
};
</script>

<style scoped></style>
