<!-- Homepage.vue -->
<template>
  <div>
    <SiteHeader />

    <!-- Start hero Area -->
    <div class="hero-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 text-center">
            <div class="hero-content">
              <span>Boost Your Business</span>
              <h1>ONE STOP SOLUTIONS OF YOUR SOFTWARE REQUIREMENTS <br><span class="sub-text">Find your software with Us
                  -> Send Enquiry -> Take Demo -> If satisfied then only go with us</span></h1>
              <!-- <p>
                SOFTWARE HUB will help you to meet your business software
                requirements. We have listed top level software catagories in
                our list. Anyone can select the software from our software
                catagory as per user requirements. We will help to conduct
                product demonstrate and remaining discussion related to buying a
                product. If the user satisfied with product features &
                functionality/pricing/support terms, then only they place order.
              </p> -->

              <div method="get" class="custom-form mt-4 pt-2 mb-lg-0 mb-5" role="search">
                <div class="input-group input-group-lg">
                  <span class="input-group-text bi-search" id="basic-addon1">
                  </span>

                  <!-- <input name="keyword" type="search" class="form-control" id="keyword"
                    placeholder="Search products here...." aria-label="Search" /> -->

                  <vue3-basic-autocomplete v-model="searchterm" @keydown.enter="openDetailsModal(searchterm)"
                    :options="Object.keys(allSoftwares)" input-class="auto-fc form-control" />

                  <button type="submit" @click="openDetailsModal(searchterm)" class="form-control">Search</button>
                </div>
              </div>
            </div>
            <marquee data-v-df02a584="" class="marq" direction="left">
              <div data-v-df02a584="" class="text"> For Enquiry Call : +91 74200 61266 / +91 74200 61259 </div>
            </marquee>
          </div>
        </div>
      </div>

      <div class="position_absolute curve_shape_bottom_1 z_99">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 27.8" preserveAspectRatio="none">
          <path class="elementor-shape-fill"
            d="M283.5,9.7c0,0-7.3,4.3-14,4.6c-6.8,0.3-12.6,0-20.9-1.5c-11.3-2-33.1-10.1-44.7-5.7	s-12.1,4.6-18,7.4c-6.6,3.2-20,9.6-36.6,9.3C131.6,23.5,99.5,7.2,86.3,8c-1.4,0.1-6.6,0.8-10.5,2c-3.8,1.2-9.4,3.8-17,4.7	c-3.2,0.4-8.3,1.1-14.2,0.9c-1.5-0.1-6.3-0.4-12-1.6c-5.7-1.2-11-3.1-15.8-3.7C6.5,9.2,0,10.8,0,10.8V0h283.5V9.7z M260.8,11.3	c-0.7-1-2-0.4-4.3-0.4c-2.3,0-6.1-1.2-5.8-1.1c0.3,0.1,3.1,1.5,6,1.9C259.7,12.2,261.4,12.3,260.8,11.3z M242.4,8.6	c0,0-2.4-0.2-5.6-0.9c-3.2-0.8-10.3-2.8-15.1-3.5c-8.2-1.1-15.8,0-15.1,0.1c0.8,0.1,9.6-0.6,17.6,1.1c3.3,0.7,9.3,2.2,12.4,2.7	C239.9,8.7,242.4,8.6,242.4,8.6z M185.2,8.5c1.7-0.7-13.3,4.7-18.5,6.1c-2.1,0.6-6.2,1.6-10,2c-3.9,0.4-8.9,0.4-8.8,0.5	c0,0.2,5.8,0.8,11.2,0c5.4-0.8,5.2-1.1,7.6-1.6C170.5,14.7,183.5,9.2,185.2,8.5z M199.1,6.9c0.2,0-0.8-0.4-4.8,1.1	c-4,1.5-6.7,3.5-6.9,3.7c-0.2,0.1,3.5-1.8,6.6-3C197,7.5,199,6.9,199.1,6.9z M283,6c-0.1,0.1-1.9,1.1-4.8,2.5s-6.9,2.8-6.7,2.7	c0.2,0,3.5-0.6,7.4-2.5C282.8,6.8,283.1,5.9,283,6z M31.3,11.6c0.1-0.2-1.9-0.2-4.5-1.2s-5.4-1.6-7.8-2C15,7.6,7.3,8.5,7.7,8.6	C8,8.7,15.9,8.3,20.2,9.3c2.2,0.5,2.4,0.5,5.7,1.6S31.2,11.9,31.3,11.6z M73,9.2c0.4-0.1,3.5-1.6,8.4-2.6c4.9-1.1,8.9-0.5,8.9-0.8	c0-0.3-1-0.9-6.2-0.3S72.6,9.3,73,9.2z M71.6,6.7C71.8,6.8,75,5.4,77.3,5c2.3-0.3,1.9-0.5,1.9-0.6c0-0.1-1.1-0.2-2.7,0.2	C74.8,5.1,71.4,6.6,71.6,6.7z M93.6,4.4c0.1,0.2,3.5,0.8,5.6,1.8c2.1,1,1.8,0.6,1.9,0.5c0.1-0.1-0.8-0.8-2.4-1.3	C97.1,4.8,93.5,4.2,93.6,4.4z M65.4,11.1c-0.1,0.3,0.3,0.5,1.9-0.2s2.6-1.3,2.2-1.2s-0.9,0.4-2.5,0.8C65.3,10.9,65.5,10.8,65.4,11.1	z M34.5,12.4c-0.2,0,2.1,0.8,3.3,0.9c1.2,0.1,2,0.1,2-0.2c0-0.3-0.1-0.5-1.6-0.4C36.6,12.8,34.7,12.4,34.5,12.4z M152.2,21.1	c-0.1,0.1-2.4-0.3-7.5-0.3c-5,0-13.6-2.4-17.2-3.5c-3.6-1.1,10,3.9,16.5,4.1C150.5,21.6,152.3,21,152.2,21.1z">
          </path>
          <path class="elementor-shape-fill"
            d="M269.6,18c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C267.7,18.8,269.7,18,269.6,18z">
          </path>
          <path class="elementor-shape-fill"
            d="M227.4,9.8c-0.2-0.1-4.5-1-9.5-1.2c-5-0.2-12.7,0.6-12.3,0.5c0.3-0.1,5.9-1.8,13.3-1.2	S227.6,9.9,227.4,9.8z">
          </path>
          <path class="elementor-shape-fill"
            d="M204.5,13.4c-0.1-0.1,2-1,3.2-1.1c1.2-0.1,2,0,2,0.3c0,0.3-0.1,0.5-1.6,0.4	C206.4,12.9,204.6,13.5,204.5,13.4z">
          </path>
          <path class="elementor-shape-fill"
            d="M201,10.6c0-0.1-4.4,1.2-6.3,2.2c-1.9,0.9-6.2,3.1-6.1,3.1c0.1,0.1,4.2-1.6,6.3-2.6	S201,10.7,201,10.6z">
          </path>
          <path class="elementor-shape-fill"
            d="M154.5,26.7c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C152.6,27.5,154.6,26.8,154.5,26.7z">
          </path>
          <path class="elementor-shape-fill"
            d="M41.9,19.3c0,0,1.2-0.3,2.9-0.1c1.7,0.2,5.8,0.9,8.2,0.7c4.2-0.4,7.4-2.7,7-2.6	c-0.4,0-4.3,2.2-8.6,1.9c-1.8-0.1-5.1-0.5-6.7-0.4S41.9,19.3,41.9,19.3z">
          </path>
          <path class="elementor-shape-fill"
            d="M75.5,12.6c0.2,0.1,2-0.8,4.3-1.1c2.3-0.2,2.1-0.3,2.1-0.5c0-0.1-1.8-0.4-3.4,0	C76.9,11.5,75.3,12.5,75.5,12.6z">
          </path>
          <path class="elementor-shape-fill"
            d="M15.6,13.2c0-0.1,4.3,0,6.7,0.5c2.4,0.5,5,1.9,5,2c0,0.1-2.7-0.8-5.1-1.4	C19.9,13.7,15.7,13.3,15.6,13.2z">
          </path>
        </svg>
      </div>
    </div>

    <!-- End hero Area -->

    <div class="top_software pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span class="subtitle">Categories</span>
          <h2>Select Your Software From Our Listed Software Catagories</h2>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-4">
            <ul class="category_list">
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'Accounting / Inventory / GST Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Accounting/Inventory/GST Software </router-link><i
                  class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'HR / Payroll Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />HR/Payroll
                  Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'Restaurant Management Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Resturant
                  Management Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'Biometric Machine For Attendance' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />BIO Metric
                  Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-xl-4">
            <ul class="category_list">
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'Tally Customization / Mobile App' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Tally
                  Customization / Mobile App</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'CRM Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />CRM
                  Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>

              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'Hotel Management Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Hotel Management
                  Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{ name: 'CategoryList', query: { name: 'Antivirus' } }"
                  :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Antivirus</router-link><i
                  class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-xl-4">
            <ul class="category_list">
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: {
                    name: 'Cloud / Desktop ERP for Manufacturer / distributor / Retailer / Export / Import',
                  },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Cloud / Desktop
                  ERP Software for Distribution / Manufacturers / Export /
                  Import / Retail</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'School Management Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />School Management
                  Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
              <li>
                <router-link :to="{
                  name: 'CategoryList',
                  query: { name: 'Fixed Asset Management Software' },
                }" :class="{ active: $route.name === 'CategoryList' }">
                  <img src="../assets/img/logo_flaticon.png" />Fixed Asset
                  Management Software</router-link><i class="fas fa-angle-double-right" aria-hidden="true"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- start top products -->
    <div class="best-service-area pt-100 pb-70 bg-color-f9f9f9">
      <div class="container">
        <div class="section-title">
          <span class="subtitle">Top Products</span>
          <h2>Our Awesome Top Products</h2>
        </div>
        <carousel :items-to-show="3" :breakpoints="breakpoints" :wrapAround="true" :autoplay="2000">
          <slide v-for="slide in topProducts" :key="slide">
            <div class="awesome-card">
              <div class="awesome-image">
                <img :src="slide['prd-img']" alt="softwarehub product">
              </div>
              <h4>{{ slide['prd-name'] }}</h4>
            </div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>
    <!-- end top products -->

    <!--Partners slider start-->
    <div class="partners-area pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span class="subtitle">Our Association</span>
          <h2>We Are Associated With</h2>
        </div>
        <div class="partners-slider">
          <carousel :items-to-show="5" :autoplay="2000" :wrapAround="true" :breakpoints="breakpointsPartners">
            <slide v-for="slider in partners" :key="slider">
              <div class="sponsor-logo">
                <img :src="slider['prd-img']" alt="softwarehub partners" />
              </div>
            </slide>

            <template #addons>
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div>

    <ProductDetail :selectedSoftwareName="searchterm" :selectedSoftware=allSoftwares[searchterm]
      @updatePdModalVisiblity="updatePdModalVisiblity" :detailsModalVisible="detailsModalVisible">
    </ProductDetail>
    <!--Partners slider start-->

    <SiteFooter />
  </div>
</template>

<script>
  import SiteHeader from "./SiteHeader.vue";
  import SiteFooter from "./SiteFooter.vue";
  import ProductDetail from "./ProductDetail.vue";
  import Vue3BasicAutocomplete from 'vue3-basic-autocomplete'

  import { Carousel, Slide, Navigation } from "vue3-carousel";

  export default {
    name: "HomePage",
    components: {
      SiteHeader,
      SiteFooter,
      Carousel,
      Slide,
      Navigation,
      ProductDetail,
      Vue3BasicAutocomplete
    },
    data: () => ({
      allSoftwares: {},
      selectedCategory: null,
      visible: false,
      searchterm: "",
      sname: "",
      detailsModalVisible: false,
      topProducts: [],
      partners: [],
      breakpoints: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
          showNextButton: false,
          showPreviousButton: false,
        },
        512: {
          itemsToShow: 1,
          snapAlign: "center",
          showNextButton: false,
          showPreviousButton: false,
        },
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "center",
        },
      },
      breakpointsPartners: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
          showNextButton: false,
          showPreviousButton: false
        },
        512: {
          itemsToShow: 2,
          snapAlign: "center",
          showNextButton: false,
          showPreviousButton: false
        },
        700: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "center",
        },
      },
    }),

    mounted() {
      // Fetch data from the API
      fetch("https://softwarehub.co.in/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.categories = data.categories;
          this.softwares = data.softwares;
          this.allSoftwares = Object.keys(data.softwares).reduce((acc, key) => {
            return { ...acc, ...data.softwares[key] };
          }, {});
          this.topProducts = data.top_products;
          this.partners = data.partners;
        });

    },
    methods: {
      openDetailsModal(searchterm) {
        console.log("openDetailsModal(searchterm) " + searchterm);
        this.sname = searchterm;
        this.detailsModalVisible = true;
      },
      updatePdModalVisiblity(secret) {
        console.log("sec " + secret);
        this.detailsModalVisible = secret;
      },
    }
  };
</script>

<style scoped>
  .hero-content form {
    width: 60%;
    margin: 0 auto;
  }

  .hero-content .input-group-text {
    background-color: transparent;
    border: 0;
  }

  .hero-content .input-group {
    background-color: var(--white-color);
    border-radius: var(--border-radius-large);
    padding: 10px 15px;
  }

  .hero-content .input-group-text {
    background-color: transparent;
    border: 0;
  }

  .hero-content .input-group {
    background-color: #fff;
    border-radius: 50px;
    padding: 10px 15px;
  }

  .hero-content input[type="search"] {
    border: 0;
    box-shadow: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  .custom-form .form-control {
    border-radius: var(--border-radius-large);
    color: var(--p-color);
    margin-bottom: 24px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 20px;
    outline: none;
  }

  .custom-form button[type="submit"] {
    background: linear-gradient(95.32deg, #8c68d2 -0.67%, #470bbf 73.27%);
    border: none;
    border-radius: 50px !important;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    transition: all 0.3s;
    margin-bottom: 0;
    max-width: 150px;
  }

  .category_list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .category_list li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: 2px solid #e7b671;
    border-radius: 10px;
    color: #000000;
    margin-bottom: 10px;
    z-index: 1;
  }

  .category_list img {
    width: 5%;
    margin-right: 10px;
  }

  .category_list li a {
    font-weight: 600;

    font-size: 16px;
    text-indent: -30px;
    margin-left: 30px;
  }

  .category_list li:hover a {
    color: #ffffff;
  }

  .category_list li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    z-index: -1;
    border-radius: 5px;
    background: linear-gradient(95.32deg, #8c68d2 -0.67%, #470bbf 73.27%);
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.5s;
  }

  .category_list li:hover::before {
    opacity: 1;
    width: 100%;
    visibility: visible;
  }

  .position_absolute {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }

  .curve_shape_bottom_1 {
    transform: rotate(180deg);
  }

  .curve_shape_bottom_1 svg {
    width: calc(100% + 1.3px);
    height: 62px;
    display: block;
    transform: rotateY(180deg);
  }

  .curve_shape_bottom_1 svg path {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
  }

  marquee .text {
    padding: 10px;
    font-size: 25px;
    color: #ffffff;
  }
</style>