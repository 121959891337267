<!-- Homepage.vue -->
<template>
  <div>
    <SiteHeader />
    <div class="page-banner-area bg-color-f8f8f8">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <div class="page-banner-heading">
              <h1>About Software Hub</h1>
              <ul>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-7 col-md-6"></div>
        </div>
      </div>
    </div>

    <div class="modern-graphic-area about-area pt-70 pb-70">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
           
            <img src="../assets/img/about_us.jpg">
          </div>

          <div class="col-lg-6">
            <div class="modern-info">
              <div class="section-heading">
                <span class="sub-title">About Us</span>
                <h2>Why You Should Select Software Hub?</h2>
                <p>
                  Software Hub is providing solutions in business software. We
                  have 18 + Years of experience in the software verticals. Now
                  we are going to take the software requirements from the
                  clients, as per our listed product and we will be going to
                  provide a solution on the same. We provide 
                </p>
              </div>

              <ul>
                <li><i class="fa-solid fa-check"></i>Innovative Solutions </li>
                <li><i class="fa-solid fa-check"></i>Exceptional Customer Support </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12">
            <br>
            <p>
              Using this website client can select the exact match of their
              business software from our listed product, after selecting client
              can post their requirements, our expert from software hub team
              will communicate with client, fix an appointment for demo then
              client can buy the product only if client is satisfied with
              product / pricing / service.
            </p>

            <p>
              We strongly recommend to client, before buying the any software,
              take demo of the product, present all your requirement at the time
              of demo, be clear about pricing and services and you seem it is ok
              then buy the product.
            </p>

            <p>
              Software hub going to be a, software consultant for those who are
              finding any business software for their business. We will add more
              &amp; more categories time to time, so client can select the exact
              product from wide range of options.
            </p>

            <p>
              Software hub will take the requirements of business software from
              clients &amp; we will analyse the requirements, and will provide
              the solutions to the clients. Day by day we are associating with
              Parent companies of software, so will directly forward the call to
              the parent companies and from parent company’s solutions will be
              provide to the clients as per their policy, some software
              solutions will be providing by software hub team.
            </p>
            <p>
              We have multiple software categories, so you can select as per
              your requirements / budget. When you post any requirements to us
              we will understood your requirements &amp; we arrange a demo from
              the parent company. When you select software hub your time will
              save. We will act as your “Trusted Software Consultant”
            </p>
          </div>
        </div>
      </div>

      <img
        class="about-graphics-shape animationFramesTwo"
        src="../assets/img/graphic-shape-2.png"
        alt="image"
      />
      <img
        class="about-graphics-shape-2 bounce"
        src="../assets/img/graphic-shape-3.png"
        alt="image"
      />
    </div>
    <!-- start top products -->
    <div class="best-service-area pt-100 pb-70 bg-color-f9f9f9">
      <div class="container">
        <div class="section-title">
          <span class="subtitle">Top Products</span>
          <h2>Our Awesome Top Products</h2>
        </div>
        <carousel :items-to-show="3" :breakpoints="breakpoints" :wrapAround="true" :autoplay="2000">
          <slide v-for="slide in topProducts" :key="slide">
            <div class="awesome-card">
              <div class="awesome-image">
                <img :src="slide['prd-img']" alt="softwarehub product">
              </div>
              <h4>{{ slide['prd-name'] }}</h4>
            </div>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </div>
    <!-- end top products -->


    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from "./SiteHeader.vue";
import SiteFooter from "./SiteFooter.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "AboutUs",
  components: {
    SiteHeader,
    SiteFooter,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    topProducts: [],
    breakpoints: {
      320: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      512: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      700: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: "center",
      },
    },
    breakpointsPartners: {
      320: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      512: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      700: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: "center",
      },
    },
  }),

  mounted() {
      // Fetch data from the API
      fetch("https://softwarehub.co.in/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.categories = data.categories;
          this.softwares = data.softwares;
          this.allSoftwares = Object.keys(data.softwares).reduce((acc, key) => {
            return { ...acc, ...data.softwares[key] };
          }, {});
          this.topProducts = data.top_products;
          this.partners = data.partners;
        });

    },
};
</script>

<style scoped>
</style>
