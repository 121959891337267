 /* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import PrimeVue from 'primevue/config';
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import 'primevue/resources/themes/aura-light-green/theme.css';
import InputText from 'primevue/inputtext';

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});
const app = createApp(App);
app.use(PrimeVue, { ripple: true })
app.component('Button', Button);
app.component('Dialog', Dialog);
app.component('InputText', InputText)
app.use(router)
app.mount('#app')

