import { createWebHistory, createRouter } from "vue-router";
import HomePage from "../components/HomePage.vue";
import AboutUs from "../components/AboutUs.vue";
import CategoryList from "../components/CategoryList.vue";
import ContactUs from "../components/ContactUs.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/categories",
    name: "CategoryList",
    component: CategoryList,
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;