<template>
  <Dialog
    ref="dialog"
    v-model:visible="dialogVisible"
    maximizable
    modal
    header=" "
    :visible="show"
    @update:visible="handleClose"
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    class="prd-modal"
  >
    <img
      :src="selectedSoftware.product_image"
      class="prd-img"
    />
    <h4>{{ selectedSoftwareName }}</h4>
    <div class="cnt-whatsapp-container">
    <a href="https://api.whatsapp.com/send?phone=7420061266" class="cnt-whatsapp" target="_blank">
      Connect On whatsapp <i class="fa fa-whatsapp" aria-hidden="true"></i>
    </a>
    <div style="margin-top: 6px;;">+91 7420061266 | +91 7420061259</div>
  </div>
  <div class="clearfix"></div>
    <hr />
    <div v-html="selectedSoftware.long_desc"></div>
  </Dialog>
</template>

<script>
export default {
  name: "ProductDetail",
  props: {
    detailsModalVisible: Boolean,
    selectedSoftware: Object,
    selectedSoftwareName: String,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    // Watch changes to the prop and update local data accordingly
    detailsModalVisible(newValue) {
      this.dialogVisible = newValue;
      console.log("watch dialogVisible " + newValue);
    },
  },
  methods: {
    toggleDialog() {
      this.dialogVisible = false;
      this.$emit("updatePdModalVisiblity", this.dialogVisible);
    },
    handleClose() {
      this.toggleDialog();
    },
    maximizeDialog() {
      // Custom logic when dialog is maximized, if needed
    }
  },
  // Reset dialog visibility when component is mounted
  mounted() {
    this.dialogVisible = this.detailsModalVisible;
    this.$nextTick(() => {
      if (this.$refs.dialog) {
        this.$refs.dialog.maximize();
      }
    });
  },
};
</script>
