<!-- App.vue -->
<template>
  
    <router-view />
  
</template>

<script>
import jQuery from "jquery";

export default {
  components: {
   
  },
  mounted() {
    jQuery("#app").css("background-color", "white");
  },
};
</script>


<style>
@import "assets/css/bootstrap.min.css";
@import "assets/css/owl.carousel.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import "assets/css/style.css";
@import "assets/css/responsive.css";
@import "assets/css/mob-menu.min.css";
</style>
