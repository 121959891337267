<!-- Header.vue -->
<template>
  <header>
    <!-- Start Navbar Area -->
    <div class="navbar-area navbar-area-3 fixed-top">
      <div class="mobile-responsive-nav">
        <div class="container">
          <div class="mobile-responsive-menu mean-container">
            <div class="mean-bar">
              <a href="#nav" class="meanmenu-reveal"
                style="right: 0; left: auto"><span><span><span></span></span></span></a>
              <nav class="mean-nav">
                <ul class="navbar-nav ms-auto" style="display: none;">
                  <li class="nav-item">
                    <router-link :to="{ name: 'HomePage' }" :class="{ 'active': $route.name === 'HomePage' }">
                      Home
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'CategoryList' }" :class="{ 'active': $route.name === 'CategoryList' }">
                      Categories
                    </router-link>

                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'AboutUs' }" :class="{ 'active': $route.name === 'AboutUs' }">
                      About Us
                    </router-link>
                  </li>
                  <li class="nav-item mean-last">
                    <router-link :to="{ name: 'ContactUs' }"
                      :class="{ 'active': $route.name === 'ContactUs' }">Contact</router-link>
                  </li>
                </ul>

              </nav>
            </div>
            <div class="logo">
              <router-link to="/">
                <img src="../assets/img/logo-v3.png" class="main-logo" alt="logo" />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="desktop-nav">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-md navbar-light">
            <router-link :to="{ name: 'HomePage' }" :class="{ 'active': $route.name === 'HomePage' }">
              <img
                src="../assets/img/logo-v3.png"
                class="main-logo"
                alt="logo"
              />
            </router-link>
            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <router-link :to="{ name: 'HomePage' }"
                    :class="{ 'active': $route.name === 'HomePage' }">Home</router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{ name: 'CategoryList' }"
                    :class="{ 'active': $route.name === 'CategoryList' }">Categories</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'AboutUs' }" :class="{ 'active': $route.name === 'AboutUs' }">About
                    Us</router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{ name: 'ContactUs' }" :class="{ 'active': $route.name === 'ContactUs' }">Contact
                    Us</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <!-- Nnd Navbar Area -->
  </header>
</template>

<script>
import jQuery from "jquery";

export default {
  components: {

  },
  mounted() {
    jQuery(".meanmenu-reveal").click(function () {
      jQuery(".navbar-nav.ms-auto").slideToggle();
    });

  },
};
</script>

<style scoped>
/* Header component styles go here */
</style>
