<!-- Homepage.vue -->
<template>
  <div>
    <SiteHeader />

    <div class="page-banner-area bg-color-f8f8f8">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <div class="page-banner-heading">
              <h1>Category Listing</h1>
              <ul>
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li>Categories</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-7 col-md-6"></div>
        </div>
      </div>
    </div>

    <!--listing category starts -->
    <section class="pt-70">
      <div class="container">
        <div class="row">
          <!-- sidebar -->
          <div class="col-lg-4">
            <!-- Toggle button -->
            <button class="btn btn-outline-secondary mb-3 w-100 d-lg-none" type="button" data-mdb-toggle="collapse"
              aria-expanded="false" aria-label="Toggle navigation" id="toggleButton">
              <span>Category filter</span>
            </button>
            <!-- Collapsible wrapper -->
            <div class="collapse card d-lg-block mb-5" id="filter-category">
              <div class="categories-box">
                <h4>Categories</h4>
                <ul>
                  <li @click="selectCategory(null)" class="categorylistitem"
                    :class="{ active: selectedCategory === null }">
                    <i class="fa-solid fa-angles-right"></i> All Softwares ({{
                      allSoftwaresCount
                    }})
                  </li>
                  <li v-for="(category, index) in categories" :key="index" class="categorylistitem"
                    @click="selectCategory(category)" :class="{ active: selectedCategory === category }">
                    <i class="fa-solid fa-angles-right"></i> {{ category }} ({{
                      getCategoryCount(category)
                    }})
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- sidebar -->
          <!-- content -->
          <div class="col-lg-8 prd_list pb-70">
            <header class="d-sm-flex align-items-center border-bottom mb-4 pb-3">
              <strong class="d-block py-2">{{ getCategoryCount(selectedCategory) }} Items found.
              </strong>
            </header>

            <div v-if="selectedCategory === null">
              <div v-if="Object.keys(allSoftwares).length === 0">
                <!-- Display "Coming soon" image -->
                <h2>Coming Soon with this category products <br>- Team Softwarehub</h2>
              </div>
              <div v-else>
                <div class="row justify-content-center mb-3 p1 prd_item" v-for="(software, softwareName) in sortedAllSoftwares" :key="softwareName">
                  <div class="col-md-12">
                    <div class="card shadow-0 border rounded-3">
                      <div class="card-body">
                        <div class="row g-0">
                          <div class="col-xl-3 col-md-4 d-flex justify-content-center">
                            <div class="bg-image hover-zoom ripple rounded ripple-surface me-md-3 mb-3 mb-md-0">
                              <img :src="software.product_image" class="w-100" />
                              <a href="#!">
                                <div class="hover-overlay">
                                  <div class="mask" style="
                                      background-color: rgba(253, 253, 253, 0.15);
                                    "></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-xl-9 col-md-8 col-sm-12">
                            <h5>{{ softwareName }}</h5>

                            <p class="text mb-4 mb-md-0">
                              {{ software.short_desc }}
                            </p>
                            <div class="mt-4">
                              <Button label="More Details" @click="openDetailsModal(softwareName)"
                                class="btn shadow-0 details-btn" />
                              <Button label="Enquire Now" @click="openEnquireModal(softwareName)"
                                class="btn shadow-0 default-btn enquire-btn" type="button" />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <div v-if="Object.keys(softwares[selectedCategory] || {}).length === 0">
                <!-- Display "Coming soon" image -->
                <h2>Coming Soon with this category products <br>- Team Softwarehub</h2>
              </div>

              <div v-else>
                <div class="row justify-content-center mb-3 p6 prd_item" v-for="(software, softwareName) in sortedCategorySoftwares" :key="softwareName">
                  <div class="col-md-12">
                    <div class="card shadow-0 border rounded-3">
                      <div class="card-body">
                        <div class="row g-0">
                          <div class="col-xl-3 col-md-4 d-flex justify-content-center">
                            <div class="bg-image hover-zoom ripple rounded ripple-surface me-md-3 mb-3 mb-md-0">
                              <img :src="software.product_image" class="w-100" />
                              <a href="#!">
                                <div class="hover-overlay">
                                  <div class="mask" style="
                                      background-color: rgba(253, 253, 253, 0.15);
                                    "></div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="col-xl-9 col-md-8 col-sm-12">
                            <h5>{{ softwareName }}</h5>

                            <p class="text mb-4 mb-md-0">
                              {{ software.short_desc }}
                            </p>
                            <div class="mt-4">
                              <Button label="More Details" @click="openDetailsModal(softwareName)"
                                class="btn shadow-0 details-btn" />
                              <Button label="Enquire Now" @click="openEnquireModal(softwareName)"
                                class="btn shadow-0 default-btn enquire-btn" type="button" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>



    <EnquireModal :selectedSoftwareName="sname" @updateEqModalVisiblity="updateEqModalVisiblity"
      :enquireModalVisible="enquireModalVisible">
    </EnquireModal>
    <!--listing category end-->
    <ProductDetail :selectedSoftwareName="sname" :selectedSoftware=allSoftwares[sname]
      @updatePdModalVisiblity="updatePdModalVisiblity" :detailsModalVisible="detailsModalVisible">
    </ProductDetail>

    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from "./SiteHeader.vue";
import SiteFooter from "./SiteFooter.vue";
import EnquireModal from "./EnquireModal.vue";
import ProductDetail from "./ProductDetail.vue";
import jQuery from "jquery";

export default {
  name: "CategoryList",
  components: {
    SiteHeader,
    SiteFooter,
    EnquireModal,
    ProductDetail,
  },
  data() {
    return {
      categories: [],
      softwares: {},
      allSoftwares: {},
      selectedCategory: null,
      visible: false,
      sname: "",
      detailsModalVisible: false,
      enquireModalVisible: false,
      selectedurlcategoryName: null,
    };
  },
  computed: {
    allSoftwaresCount() {
      return Object.keys(this.allSoftwares).length;
    },
    // Computed property to sort allSoftwares by index key
    sortedAllSoftwares() {
      // Convert allSoftwares object to an array of [key, value] pairs
      const entries = Object.entries(this.allSoftwares);
      // Sort the array by index key
      const sortedEntries = entries.sort((a, b) => a[1].index - b[1].index);
      // Convert the sorted array back to an object
      const sortedObject = Object.fromEntries(sortedEntries);
      return sortedObject;
    },
    // Computed property to sort softwares[selectedCategory] by index key
    sortedCategorySoftwares() {
      const category = this.selectedCategory;
      if (!category || !this.softwares[category]) return {};
      // Convert softwares[selectedCategory] object to an array of [key, value] pairs
      const entries = Object.entries(this.softwares[category]);
      // Sort the array by index key
      const sortedEntries = entries.sort((a, b) => a[1].index - b[1].index);
      // Convert the sorted array back to an object
      const sortedObject = Object.fromEntries(sortedEntries);
      return sortedObject;
    }
  },
  mounted() {
    // Fetch data from the API
    fetch("https://api.npoint.io/271edf3fafebd6ddf61d")
      .then((response) => response.json())
      .then((data) => {
        this.categories = data.categories;
        this.softwares = data.softwares;
        this.allSoftwares = Object.keys(data.softwares).reduce((acc, key) => {
          return { ...acc, ...data.softwares[key] };
        }, {});
        if (this.selectedurlcategoryName) {
          this.selectCategory(this.selectedurlcategoryName);
        }
      });
    jQuery("#toggleButton").click(function () {
      jQuery("#filter-category").slideToggle();
    });
  },
  created() {
    // Accessing the query parameter 'name'
    const urlcategoryName = this.$route.query.name;
    this.selectedurlcategoryName = urlcategoryName;
  },
  methods: {
    selectCategory(category) {
      this.$router.push({ query: { name: category } });
      this.selectedCategory = category;
    },
    getCategoryCount(category) {
      if (category == null) {
        return Object.keys(this.allSoftwares || {}).length;
      } else {
        return Object.keys(this.softwares[category] || {}).length;
      }
    },

    openEnquireModal(softwarename) {
      console.log("openEnquireModal(softwarename) " + softwarename)
      this.sname = softwarename;

      this.enquireModalVisible = true;
    },
    updateEqModalVisiblity(secret) {
      this.enquireModalVisible = secret;
    },
    openDetailsModal(softwarename) {
      console.log("openDetailsModal(softwarename) " + softwarename);
      this.sname = softwarename;
      this.detailsModalVisible = true;
    },
    updatePdModalVisiblity(secret) {
      console.log("sec " + secret);
      this.detailsModalVisible = secret;
    },
  },
};
</script>

<style scoped></style>