<template>
  <Dialog
    v-model:visible="dialogVisible"
    modal
    header="Contact Softwarehub"
    :style="{ width: '30rem' }"
    :visible="show"
    @update:visible="handleClose"
  >
    <div class="flex align-items-center gap-3 mb-3">
      <label for="compname" class="font-semibold w-9rem">Company Name: </label>
      <InputText
        type="text"
        id="compname"
        v-model="compname"
        class="flex-auto form-control"
        autocomplete="off"
      />
    </div>
    <div class="flex align-items-center gap-3 mb-3">
      <label for="username" class="font-semibold w-9rem">Name: </label>
      <InputText
        type="text"
        id="username"
        v-model="custname"
        class="flex-auto form-control"
        autocomplete="off"
      />
    </div>
    <div class="flex align-items-center gap-3 mb-3">
      <label for="email" class="font-semibold w-9rem">Email:</label>
      <InputText type="email" id="email" v-model="email" class="flex-auto
      form-control" autocomplete="off" />
    </div>
    <div class="flex align-items-center gap-3 mb-3">
      <label for="number" class="font-semibold w-9rem">Contact Number:</label>
      <InputText type="number" id="number" v-model="contact" class="flex-auto
      form-control" autocomplete="off" />
    </div>
    <div class="flex align-items-center gap-3 mb-3">
      <label for="location" class="font-semibold w-9rem">Location:</label>
      <InputText type="text" id="location" v-model="location" class="flex-auto
      form-control" autocomplete="off" />
    </div>
    <div class="flex align-items-center gap-3 mb-5">
      <label for="gstin" class="font-semibold w-9rem">GSTIN:</label>
      <InputText type="text" id="gstin" v-model="gstin" class="flex-auto
      form-control" autocomplete="off" />
    </div>
    <div class="flex justify-content-center mb-3">
      <input type="hidden" :value="selectedSoftwareName" />
      <Button
        type="button"
        label="Close"
        severity="secondary"
        @click="toggleDialog"
        class="popup-cbtn"
      ></Button>
      <Button
        type="button"
        label="Send"
        @click="sendmail"
        class="default-btn popup-sbtn"
      ></Button>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "EnquireModal",
  props: {
    enquireModalVisible: Boolean,
    selectedSoftwareName: String,
  },
  data() {
    return {
      dialogVisible: false,
      show: true,
      custname: "",
      email: "",
      contact: "",
      compname: "",
      location: "",
      gstin: "",
    };
  },
  watch: {
    enquireModalVisible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    toggleDialog() {
      this.dialogVisible = false;
      this.$emit("updateEqModalVisiblity", this.dialogVisible);
    },
    sendmail() {
      // Check if required fields are filled
      if (!this.custname || !this.email || !this.contact || !this.location) {
        alert("Please fill in all fields.");
        return;
      }

      // Construct the request body
      const requestBody = new FormData();
      requestBody.append("name", this.custname);
      requestBody.append("email", this.email);
      requestBody.append("contact_number", this.contact);
      requestBody.append("compname", this.compname);
      requestBody.append("location", this.location);
      requestBody.append("software", this.selectedSoftwareName);
      requestBody.append("gstin", this.gstin);

      // Make the POST request to your PHP API endpoint
      fetch("https://softwarehub.co.in/softhub/mail.php", {
        method: "POST",
        body: requestBody,
      })
        .then((response) => {
          if (response.ok) {
            alert("Email sent successfully");
          } else {
            throw new Error("Failed to send email");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to send email. Please try again later.");
        })
        .finally(() => {
          // Close the dialog
          this.toggleDialog();
        });
    },
    handleClose() {
      this.toggleDialog();
    },
  },
  mounted() {
    this.dialogVisible = this.enquireModalVisible;
  },
};
</script>