<template>
    <footer>
        <div class="footer-area ptb-100 bg-color-3d0660">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="address">
                            <h4>Get In Touch</h4>
                            <ul class="location">
                                <li>
                                    <i class="fa-sharp fa-solid fa-phone"></i>
                                    <a href="tel:+91 7420061266">+91 7420 061 266</a><br>
                                    <a href="tel:+91 7420061259">+91 7420 061 259</a>
                                </li>
                                <li>
                                    <i class="fa-solid fa-envelope"></i>
                                    <a href=""><span>admin@softwarehub.co.in</span></a>
                                </li>
                                <li>
                                    <i class="fa-solid fa-location-dot"></i>
                                    <span>A-18, Vishwashanti Appartment , Station pada, <br>Kulgaon Badlapur (E), Pin-421503</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3  col-md-3">
                        <div class="address">
                            <h4>Useful Link</h4>
                            <ul>
                                <li>
                                    <i class="fas fa-angle-double-right"></i>
                                    <router-link :to="{ name: 'AboutUs' }" :class="{ 'active': $route.name === 'AboutUs' }">About Us</router-link>
                                </li>
                                
                                <li>
                                    <i class="fas fa-angle-double-right"></i>
                                   <router-link :to="{ name: 'ContactUs' }" :class="{ 'active': $route.name === 'ContactUs' }">Contact Us</router-link>
                                </li>
                                <li>
                                    <i class="fas fa-angle-double-right"></i>
                                    <router-link :to="{ name: 'CategoryList' }" :class="{ 'active': $route.name === 'CategoryList' }">Categories</router-link>
                                </li>
                                <li>
                                    <i class="fas fa-angle-double-right"></i>
                                    <router-link :to="{ name: 'HomePage' }" :class="{ 'active': $route.name === 'HomePage' }">Terms and Conditions</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <div class="widget">
                            <h4>Connect Us</h4>
                            
                            <ul>
                                <li>
                                    <a class="social" href="https://www.facebook.com/" target="_blank">
                                        <i class="fa-brands fa-square-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="social" href="https://www.twitter.com/" target="_blank">
                                        <i class="fa-brands fa-square-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="social" href="https://www.linkedin.com/" target="_blank">
                                        <i class="fa-brands fa-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a class="social" href="https://www.google.com/" target="_blank">
                                        <i class="fa-brands fa-square-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- End Footer Area -->
        
        <!-- Start Copy Area -->
        <div class="copy-area bg-color-3d0660">
            <div class="container">
                <div class="copy">
                    <p>Copyright by  <router-link :to="{ name: 'HomePage' }" :class="{ 'active': $route.name === 'HomePage' }">Software Hub</router-link> All Rights Reserved.</p>
                </div>
            </div>
        </div>
        <!-- End Copy Area -->
        
        <!-- Go top -->
        <div class="go-top">
            <i class="fa-solid fa-arrow-up-from-bracket"></i>
        </div>
        <!-- Go top -->
        
        <!-- Link of JS -->

    </footer>
</template>

<script>
export default {
  // Header component logic goes here
}
</script>

<style scoped>
/* Header component styles go here */
</style>